<template>
  <div class="register">
    <TopTabbar class="TopTabbar" title="账号注册"/>
    <div class="center">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="createUser.username"
            name="用户名"
            label="用户名"
            placeholder="请输入用户名"
            label-width="70px"
            :rules="[{ required:true, message:'用户名不能为空' }]"
          />
          <van-field
            v-model="createUser.mobile"
            name="手机号"
            label="手机号"
            placeholder="请输入手机号"
            label-width="70px"
            :rules="[{ pattern:phoneRegular, message: '请输入正确的手机号' }]"
          />
          <van-field
            v-model="createUser.password"
            type="password"
            name="密码"
            label="密码"
            placeholder="请输入密码"
            label-width="70px"
            :rules="[{ required:true, message:'密码不能为空' }]"
          />
          <van-field
            v-model="createUser.password2"
            type="password"
            name="确认密码"
            label="确认密码"
            placeholder="请输入再次输入密码"
            label-width="70px"
            :rules="[{ validator, message:passwordError }]"
          />
          <van-field
            v-model="createUser.code"
            center
            clearable
            label="验证码"
            placeholder="请输入短信验证码"
            label-width="70px"
            :rules="[{ required:true, message:'请输入验证码' }]"
          >
            <template #button>
              <van-button size="small" type="primary" @click="sendCode" :disabled="codeButton || !createUser.mobile">{{ codeValue }}</van-button>
            </template>
          </van-field>
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">
            注册
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script setup>
  import { reactive,ref } from 'vue'
  import { showToast } from 'vant'
  import { useRouter } from 'vue-router'
  import { reqGetCode,reqRegister } from '../../API/login'

  let $router = useRouter()

  //手机号验证正则
  const phoneRegular = ref(/^1[3456789]\d{9}$/)

  //验证码按钮禁止
  let codeButton = ref(false)
  let codeValue = ref('发送验证码')
  

  //确认密码校验
  let passwordError = ref('')
  const validator = () => {
    if(createUser.password2 == ''){
      passwordError.value = '密码不能为空'
      return false
    }else if(createUser.password != createUser.password2){
      passwordError.value = '俩次密码不一致'
      return false
    }else{
      return true
    }
  }
  
  //注册信息
  const createUser = reactive({
    username:'',
    mobile:'',
    password:'',
    password2:'',
    code:''
  })

  //发送验证码
  const sendCode = async () => {
    codeButton.value = true
    const result = await reqGetCode({mobile:createUser.mobile,event:'register'})
    showToast(result.msg)
    let count = ref(60)
    const timer = setInterval(() => {
      count.value--
      codeValue.value = count.value + '重新发送'
      if(count.value <= 0){
      codeButton.value = false
      codeValue.value = '发送验证码'
      clearInterval(timer)
    }
    },1000)
  }

  //注册
  const onSubmit = async () => {
    let result = await reqRegister(createUser)
    showToast(result.msg)
    if(result.code == 1){
      showToast('注册成功')
      $router.push('/login')
    }
  }
</script>

<style lang="scss" scoped>
.register{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #fcf9f9;
  .TopTabbar{
    margin-bottom: 20px;
  }
  .van-field{
    font-size: 16px;
    height: 60px;
  }
}
</style>
