import request from "../utils/request";

const API = {
  GET_CODE_URL:'/api/Sms/send',               //发送验证码
  REGISTER_URL:'/api/v2/user/register',       //注册
  LOGIN_URL:'/api/v2/user/login',             //登陆
  LOGOUT_URL:'/api/v2/user/logout',           //退出登陆
}

export const reqGetCode = (data) => request.post(API.GET_CODE_URL,data)
export const reqRegister = (data) => request.post(API.REGISTER_URL,data)
export const reqLogin = (data) => request.post(API.LOGIN_URL,data)
export const reqLogOut = () => request.post(API.LOGOUT_URL)